import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted: boolean = false;
  modalRef: BsModalRef;



  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  emailreg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(this.emailreg)])
  })

  ngOnInit(): void {
  }

  get f() {
    return this.resetForm.controls;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  sendEmailToLogin:any;

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;
    localStorage.setItem('email',JSON.stringify(this.resetForm.value.email));
    this.authService.forgetPassword({email:this.resetForm.value.email.toLowerCase(), userType:1})
    .subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success(res.message);
        this.sendEmailToLogin = this.resetForm.value.email;
        // this.openModal(this.template);
        this.sendEmailToLogin = this.resetForm.value.email.toLowerCase();
        this.router.navigateByUrl('/auth/email-send');
        this.authService.SendToChild1(this.sendEmailToLogin);
        localStorage.setItem('receiveEmail', JSON.stringify(this.sendEmailToLogin));
      }else{
        this.toastr.error(res.message)
      }
    },(err)=>{
      this.toastr.error(err.error.error.message);
    })
  }

  OkPress(){
    this.modalRef.hide();
    // this.authService.SendToChild1(this.resetForm.value.email);
    // this.authService.SendToChild1(this.sendEmailToLogin);
    this.router.navigateByUrl('/email-send');
  }

}
