<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>Reporting</h1>
            <!-- <p>Hello {{userName}}, Welcome Back!</p> -->
        </ng-container>
        <h1 *ngIf="manageContractorHeading">Manage Contractors</h1>
        <h1 *ngIf="manageFeedsHeading">Manage News Feed</h1>
        <h1 *ngIf="dashboardHeading">Reporting</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="manageBusinessHeading">Manage Business</h1>
        <h1 *ngIf="manageCategoriesHeading">Manage Categories</h1>
        <h1 *ngIf="manageTransactionHeading">Manage Transactions</h1>
        <h1 *ngIf="manageCouponHeading">Manage Coupons</h1>
        <h1 *ngIf="manageMembershipHeading">Manage Membership</h1>

    </div>


    <div class="right-sec">
        <div class="userName">{{userName}} Admin </div>
        <div class="btn-group" dropdown placement="bottom right">
        </div>
    </div>
</div>

<!-- Logout fonfirm -->
<ng-template #template>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout?</p>
        <div class="modal-btn d-flex justify-content-around">
            <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
            <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
    </div>
</ng-template>