import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ManageBusinessService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    deleteBusiness(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        console.log('data', data);
        return this.http.delete(this.url + 'user/deleteHospital', httpOptions);
    }

    uploadImage(data:any){
        return this.http.post(this.url + "user/upload-image",data)
    }


    categoryList(data:any){
        return this.http.post(this.url + `category/list`,data);
    }

    addBusiness(data:any){
        return this.http.post(this.url + 'admin/create-business-profile',data);
    }


    businessList(data){
        return this.http.post(this.url + `admin/get-business-profile`,data);
    }

    updateBusinessProfile(data){
        return this.http.put(this.url + 'admin/edit-business-profile',data);
    }
}