import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageCategory {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(data:any){
        return this.http.post(this.url + 'user/upload-image', data);
    }

    addCategory(data:any){
        return this.http.post(this.url + 'category/create',data);
    }

    categoryList(data:any){
        return this.http.post(this.url + `category/list`,data);
    }

    updateCategory(data:any){
        return this.http.put(this.url + 'category/edit',data);
    }


    deleteCategory(id:any){
        return this.http.delete(this.url + `category/delete/${id}`);
    }


}