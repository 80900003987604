<app-layout></app-layout>
<div class="content">
    <div class="row mt-4">
        <div class="total-counts">
            <div class="total-count total-users col-lg-5 mt-3 card-border"
                routerLink="/analytics-dashboard/total-clients">
                <img src="../../../assets/dashboard_icons/Active users.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="client" class="counter">{{analyticsData?.totalCustomer}}</h2>
                    <p>Total Number of Registered Customers</p>
                    <h2 id="client" class="counter">{{analyticsData?.totalActiveCustomer}}</h2>
                    <p>Total Number of Active Customers</p>
                </div>
            </div>

            <div class="total-count total-careers  col-lg-5 mt-3 card-border" routerLink="/manage-business">
                <img src="../../../assets/dashboard_icons/Manage_Business_White.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="caregiver" class="counter">{{analyticsData?.totalBusiness}}</h2>
                    <p>Total Number of Registered Business </p>
                    <h2 id="caregiver" class="counter">{{analyticsData?.totalActiveBusiness}}</h2>
                    <p>Total Number of Active Business </p>
                </div>
            </div>


            <div class="total-count total-gs  col-lg-5 mt-3 card-border"
                routerLink="/analytics-dashboard/total-contractors">
                <img src="../../../assets/dashboard_icons/Active age group.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="booking">{{analyticsData?.monthlyRevenue}}</h2>
                    <p>Monthly Revenue Recurring</p>
                </div>
            </div>

            <div class="total-count total-resources  col-lg-5 mt-3 card-border" style="cursor: unset;">
                <img src="../../../assets/dashboard_icons/View Feedback.svg" alt="" height="60" width="60">
                <div>
                    <h2 id="income">${{analyticsData?.totalBnkdRevenue}}</h2>
                    <p>Total Revenue Generated </p>
                </div>
            </div>

        </div>
    </div>


</div>